:root {
  --subscribeFormBorderRadius: 8px;
  --subscribeFormLeftPadding: 0.75rem;
}

.SubscribeForm {
  display: flex;
  position: relative;
  background-color: white;
  height: 2.75rem;
  vertical-align: middle;
  border-radius: var(--subscribeFormBorderRadius);
  font-family: var(--sans);
  font-size: 1.1rem;
}

.SubscribeForm-email[type="email"] {
  display: block;
  margin: 0;
  padding: 0.325rem 7rem 0.175rem var(--subscribeFormLeftPadding);
  outline: none;
  border-radius: var(--subscribeFormBorderRadius);
  border: 2px transparent solid;
  background-color: transparent;
  letter-spacing: 0.2px;
  transition: border-color 200ms ease;
}

.SubscribeForm-email:focus:not(:disabled) {
  border-color: rgba(73, 115, 77, 0.5);
}

.SubscribeForm-email:disabled {
  background-color: rgb(252, 247, 242);
  color: #67615d;
}

.SubscribeForm-button {
  display: flex;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0.3125rem;
  color: var(--white);
  background-color: var(--green);
  box-shadow: 0 1px 2px rgba(53, 74, 60, 0.15);
  max-width: 6rem;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  line-height: 2.125rem;
}

.SubscribeForm-button:disabled {
  box-shadow: none;
  background-color: #91a497;
  color: #d5e3da;
}

.SubscribeForm-caption {
  margin-top: 1rem;
}

@media only screen and (min-width: 767px) {
  .SubscribeForm {
    margin-left: calc(var(--subscribeFormLeftPadding) * -1);
  }
}
